<template>
  <v-row>
    <v-col cols="8">
      <v-card class="pt-5">
        <v-card-title class="d-flex align-center justify-space-between">
          <span>{{ t(merchant.name) }} ({{ merchant.code }})</span>
          <span v-if="merchant.channel">{{ merchant.channel.code }}</span>
          <span v-if="merchant.country">({{ merchant.country.cca3_code }})</span>
          <div>
            <v-chip
              small
              class="v-chip-light-bg text-sm font-weight-semibold ml-2 text-capitalize"
              :class="{ 'success--text': merchant.active }"
            >
              {{ merchant.active ? 'Active' : 'Inactive' }}
            </v-chip>
          </div>
        </v-card-title>
      </v-card>

      <v-card class="mt-2">
        <v-card-title>
          Introduction
        </v-card-title>
        <v-card-text>
          <div
            class="mb-4 html-content"
            v-html="t(merchant.introduction)"
          >
          </div>

          <manage-messages-list
            :messages="merchant.messages"
            position="merchant"
            path="merchants"
            :attach-ids="[merchant.id]"
            :detach-ids="[merchant.id]"
            @updated="$emit('updated')"
          />
        </v-card-text>
      </v-card>

      <div class="text-center mt-5">
        <v-btn
          color="secondary"
          class="me-3"
          large
          @click="$emit('edit')"
        >
          Edit
        </v-btn>
      </div>
    </v-col>

    <v-col cols="4">
      <v-card class="mt-2">
        <v-card-title class="justify-center flex-column">
          Datetimes
        </v-card-title>
        <v-card-text>
          <div class="d-flex justify-space-between">
            <span>Joined At: </span>
            <span v-if="merchant.joined_at">{{ merchant.joined_at | date }}</span>
          </div>
          <div class="d-flex justify-space-between">
            <span>Created At: </span>
            {{ merchant.created_at | date }}
          </div>
          <div class="d-flex justify-space-between">
            <span>Updated At: </span>
            {{ merchant.updated_at | date }}
          </div>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import ManageMessagesList from '@/modules/distributor/views/distributor-message/ManageMessagesList.vue'
import { t } from '@/plugins/i18n'
import { date, price } from '@core/utils/filter'
import {
mdiComment, mdiCurrencyUsd, mdiEye, mdiEyeRemove,
mdiStar,
} from '@mdi/js'

export default {
  components: { ManageMessagesList },

  filters: { price, date },

  props: {
    merchant: { type: Object, required: true },
  },

  setup() {
    return {
      t,

      icons: {
        mdiStar,
        mdiComment,
        mdiCurrencyUsd,
        mdiEye,
        mdiEyeRemove,
      },
    }
  },
}
</script>

<style lang="scss">
.html-content {
  img {
    max-width: 100%;
  }
}
</style>

import { useNotifyErrors } from '@/composables'
import { destroyMerchant, fetchMerchant } from '@api/merchant/merchant'
import { ref } from '@vue/composition-api'

export default function useMerchantView() {
  const merchantData = ref(null)
  const loading = ref(false)

  // fetch data
  const loadMerchant = id => {
    merchantData.value = null
    loading.value = true

    return fetchMerchant(id)
      .then(res => {
        merchantData.value = res.data.data

        return res
      })
      .catch(useNotifyErrors)
      .finally(() => {
        loading.value = false
      })
  }

  const trashMerchant = id => destroyMerchant(id).catch(useNotifyErrors)

  return {
    loading,

    merchantData,

    loadMerchant,
    trashMerchant,
  }
}
